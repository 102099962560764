<template>
  <ion-page>
    <ion-header>
      <ion-toolbar>
        <ion-buttons slot="start">
          <ion-back-button default-href="/tabs/stores"></ion-back-button>
        </ion-buttons>
        <ion-title>
          {{ survey ? survey.name : $t('messages.loading') }}
        </ion-title>
      </ion-toolbar>
    </ion-header>

    <ion-content v-if="survey">
      <ion-card v-if="survey.todaySubmittedResponse" color="danger">
        <ion-card-content>
          {{
            $t('labels.alreadySubmittedToday', {
              date: formatDate(survey.todaySubmittedResponse.created),
            })
          }}
          <ion-button
            expand="block"
            color="dark"
            fill="outline"
            class="ion-margin-top"
            @click="onSelect(survey.todaySubmittedResponse)"
          >
            {{ $t('buttons.viewAndOrUpdate') }}
            <ion-icon :icon="icons.chevronForwardOutline" slot="end"></ion-icon>
          </ion-button>
        </ion-card-content>
      </ion-card>
      <ODNSurvey :survey="survey" :loading="loading" @saved="onSaved" />
    </ion-content>
  </ion-page>
</template>

<script>
import { mapState, mapActions } from 'vuex';

import {
  IonContent,
  IonHeader,
  IonPage,
  IonTitle,
  IonToolbar,
  IonButtons,
  IonBackButton,
  IonCard,
  IonCardContent,
  IonIcon,
  IonButton,
  toastController,
} from '@ionic/vue';
import { chevronForwardOutline } from 'ionicons/icons';

import ODNSurvey from '@c/odn-survey';

import APIService from '@s/api.service';

export default {
  name: 'StoreSurvey',
  components: {
    IonContent,
    IonHeader,
    IonPage,
    IonTitle,
    IonToolbar,
    IonButtons,
    IonBackButton,
    IonCard,
    IonCardContent,
    IonIcon,
    IonButton,
    ODNSurvey,
  },
  data() {
    return {
      loading: true,
      icons: {
        chevronForwardOutline,
      },
    };
  },
  computed: {
    ...mapState('surveys', {
      survey: 'selectedSurvey',
    }),
  },
  async ionViewDidEnter() {
    this.fetchData();
  },
  ionViewDidLeave() {
    this.loading = true;
  },
  methods: {
    ...mapActions('surveys', ['selectSurvey']),
    ...mapActions('responses', ['selectResponse']),
    formatDate(dt) {
      return this.$dayjs(dt)
        .locale(this.$i18n.locale)
        .format('ll - LT');
    },
    async fetchData() {
      try {
        const response = await APIService.get(
          `/surveys/${this.$route.params.surveyId}?storeId=${this.$route.params.id}`
        );
        this.selectSurvey(response.data);
      } catch (err) {
        const toast = await toastController.create({
          message: this.$t('messages.survey.get.error'),
          color: 'danger',
          duration: 2000,
        });
        return toast.present();
      } finally {
        this.loading = false;
      }
    },
    async onSaved() {
      const toastSuccess = await toastController.create({
        message: this.$t('messages.surveyResponse.post.success'),
        color: 'success',
        duration: 2000,
      });
      toastSuccess.present();

      this.$router.go(-1);
    },
    onSelect(response) {
      this.selectResponse(response);
      this.$router.push(`/tabs/profile/responses/${response.id}`);
    },
  },
};
</script>
